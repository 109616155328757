import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import {
	CalculateSavingsProps,
	SheetScrapCalculatorData,
	calculateSavings,
} from "./SheetScrapCalculatorData";
import { useApplication } from "@ryerson/frontend.application";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { Dropdown, IncrementDecrementInput, Option } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { CalculatorHeroContent } from "@components/Calculators/Hero";
import { navigate } from "gatsby";

export type SheetScrapCalculatorStaticContent = {
	product: string;
	thickness: string;
	standardWidth: string;
	standardLength: string;
	finishedWidth: string;
	finishedLength: string;
	numberOfPieces: string;
	cost: string;
	calculate: string;
	reset: string;
	potentialSavings: string;
	pieces: string;
	pounds: string;
	disclaimer: string;
	requestAQuote: string;
	requestAQuoteUrl: string;
};

export type WeightCalculatorProps = {
	heroContent: CalculatorHeroContent;
};

const StaticContent: LanguageContent<SheetScrapCalculatorStaticContent> = {
	en: {
		product: "Product",
		thickness: "Thickness(Gauge)",
		standardWidth: "Standard Width",
		standardLength: "Standard Length",
		finishedWidth: "Finished Width",
		finishedLength: "Finished Length",
		numberOfPieces: "Number of Pieces",
		cost: "Cost ($ per lb)",
		calculate: "Calculate",
		reset: "Reset",
		potentialSavings: "Potential Savings",
		pieces: "pieces",
		pounds: "lbs",
		disclaimer: "Calculated weights are for estimation purposes only.",
		requestAQuote: "Request a Quote",
		requestAQuoteUrl: "/contact-us#quoteRequest",
	},
	fr: {
		product: "Produit",
		thickness: "Épaisseur(calibre)",
		standardWidth: "Largeur standard",
		standardLength: "Longueur standard",
		finishedWidth: "Largeur finie",
		finishedLength: "Longueur finie",
		numberOfPieces: "Nombre de pièces",
		cost: "Coût ($ par lb)",
		calculate: "Calculer",
		reset: "Réinitialiser",
		potentialSavings: "Économies potentielles",
		pieces: "pièces",
		pounds: "lb",
		disclaimer: "Les poids calculés sont uniquement à des fins d'estimation.",
		requestAQuote: "Demander un devis",
		requestAQuoteUrl: "/contact-us#quoteRequest",
	},
	es: {
		product: "Producto",
		thickness: "Espesor(calibre)",
		standardWidth: "Ancho estándar",
		standardLength: "Longitud del acabado",
		finishedWidth: "Ancho del acabado",
		finishedLength: "Longitud del acabado",
		numberOfPieces: "Número de piezas",
		cost: "Costo ($ por lb)",
		calculate: "Calcular",
		reset: "Restablecer",
		potentialSavings: "Ahorros potenciales",
		pieces: "piezas",
		pounds: "lb",
		disclaimer: "Los pesos calculados son solo con fines de estimación.",
		requestAQuote: "Solicite una cotización",
		requestAQuoteUrl: "/contact-us#quoteRequest",
	},
};

const ImageContainer = styled.div`
	display: inline-block;
	width: 260px;
	vertical-align: top;
	height: 410px;
	background-position: center center;
	background-size: calc(100% - 40px) auto;
	background-repeat: no-repeat;
`;

const FormContainer = styled.div`
	display: inline-block;
	width: calc(100% - 260px);
	height: auto;
	padding: 40px;
	box-sizing: border-box;
	vertical-align: top;
	min-height: 410px;
	border-radius: 0 2px 2px 0;
`;

const CalculatorContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	border-radius: 2px;
`;

const FormRow = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 30px;
`;

const FormCell = styled.div`
	display: inline-block;
	width: calc(25% - 20px);
	max-width: 190px;
	min-width: 185px;
	margin-right: 20px;
	&:last-of-type {
		margin-right: 0px;
	}
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	margin-top: 22px;
	margin-bottom: 22px;
	opacity: 0.1;
`;

const MobileSpacer = styled.div`
	display: block;
	width: 100%;
	height: 15px;
`;

const MobileFormCell = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 20px;
	margin-right: 0px;
	min-width: 100%;
	max-width: 100%;
`;

const LabelIcon = styled.div`
	display: inline-block;
	margin-left: 10px;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	img {
		width: 100%;
		height: 100%;
		display: block;
	}
`;

const ResultsContainer = styled.div`
	display: block;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 35px;
	padding-left: 300px;
	padding-right: 40px;
	box-sizing: border-box;
`;

const ResultsLeft = styled.div`
	display: inline-block;
	width: 400px;
	vertical-align: middle;
`;

const ResultsRight = styled.div`
	display: inline-block;
	width: calc(100% - 400px);
	vertical-align: middle;
	text-align: right;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 140px;
`;

const Span = styled.span``;

const WeightCalculator: React.FC<WeightCalculatorProps> = ({ heroContent }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const defaultProduct = SheetScrapCalculatorData[language][0].product;
	const [product, setProduct] = React.useState(defaultProduct);
	const products: Option[] = [];
	SheetScrapCalculatorData[language].forEach((productObject: any) => {
		products.push({
			value: productObject.product,
			display: productObject.display.charAt(0).toUpperCase() + productObject.display.slice(1),
		});
	});

	const defaultThickness = SheetScrapCalculatorData[language][0].thickness[0];
	const defaultThicknessOptions: Option[] = [];
	SheetScrapCalculatorData[language][0].thickness.forEach((thicknessOption: string) => {
		defaultThicknessOptions.push({
			display: thicknessOption,
			value: thicknessOption,
		});
	});
	const [thicknessOptions, setThicknessOptions] = React.useState(defaultThicknessOptions);

	React.useEffect(() => {
		let newThicknessOptions = [...thicknessOptions];
		newThicknessOptions.length = 0;
		SheetScrapCalculatorData[language].forEach((data) => {
			if (data.product === product) {
				data.thickness.forEach((thicknessOption: string) => {
					newThicknessOptions.push({
						display: thicknessOption,
						value: thicknessOption,
					});
				});
			}
		});
		setThicknessOptions(newThicknessOptions);
	}, [product]);

	const getInches = (uom: any, value: number) => {
		switch (uom) {
			case "ft":
				return value * 12;
			case "yd":
				return value * 36;
			case "mm":
				return value / 25.4;
			case "cm":
				return value / 2.54;
			case "m":
				return value * 39.3701;
			default:
				return value;
		}
	};

	const uomOptions: LanguageContent<Option[]> = {
		en: [
			{
				display: "in",
				value: "in",
			},
			{
				display: "ft",
				value: "ft",
			},
			{
				display: "yd",
				value: "yd",
			},
			{
				display: "mm",
				value: "mm",
			},
			{
				display: "cm",
				value: "cm",
			},
			{
				display: "m",
				value: "m",
			},
		],
		fr: [
			{
				display: "po",
				value: "in",
			},
			{
				display: "pi",
				value: "ft",
			},
			{
				display: "vg",
				value: "yd",
			},
			{
				display: "mm",
				value: "mm",
			},
			{
				display: "cm",
				value: "cm",
			},
			{
				display: "m",
				value: "m",
			},
		],
		es: [
			{
				display: "pulg.",
				value: "in",
			},
			{
				display: "pie",
				value: "ft",
			},
			{
				display: "yarda",
				value: "yd",
			},
			{
				display: "mm",
				value: "mm",
			},
			{
				display: "cm",
				value: "cm",
			},
			{
				display: "m",
				value: "m",
			},
		],
	};

	const calculate = () => {
		let calculationRequirements: CalculateSavingsProps = {
			desiredWidth: getInches(fwUOM, finishedWidth),
			desiredLength: getInches(flUOM, finishedLength),
			standardWidth: getInches(swUOM, standardWidth),
			standardLength: getInches(slUOM, standardLength),
			thickness: thickness,
			numberOfSheets: numberOfPieces,
			cost: cost,
			product: product,
		};
		let result = calculateSavings(calculationRequirements);
		setSavings(result.savings);
		setPounds(result.weight);
		setCost(result.cost);
		setNumberOfPieces(result.numberOfSheets);
	};

	const reset = () => {
		setProduct(defaultProduct);
		setThickness(defaultThickness);
		setStandardWidth(0);
		setStandardLength(0);
		setFinishedWidth(0);
		setFinishedLength(0);
		setNumberOfPieces(1);
		setCost(0);
		setPounds(0.0);
		setSavings(0.0);
	};

	const [thickness, setThickness] = React.useState(defaultThickness);
	const [standardWidth, setStandardWidth] = React.useState(0);
	const [swUOM, setSwUOM] = React.useState("in");
	const [standardLength, setStandardLength] = React.useState(0);
	const [slUOM, setSlUOM] = React.useState("in");
	const [finishedWidth, setFinishedWidth] = React.useState(0);
	const [fwUOM, setFwUOM] = React.useState("in");
	const [finishedLength, setFinishedLength] = React.useState(0);
	const [flUOM, setFlUOM] = React.useState("in");
	const [numberOfPieces, setNumberOfPieces] = React.useState(1);
	const [cost, setCost] = React.useState(0);
	const [pounds, setPounds] = React.useState<string | number>(0.0);
	const [savings, setSavings] = React.useState<string | number>(0.0);
	const formatNumber = (number: any) => {
		return parseFloat(number).toLocaleString();
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px">
					<CalculatorContainer
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<ImageContainer
							css={css`
								background-image: url(/images/metal-resources/tools-calculators/weight-calculator/SheetScrapCalculator.png);
							`}
						/>
						<FormContainer
							css={css`
								background-color: ${theme.colors.secondary.background};
							`}
						>
							<FormRow>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
										size="md"
										type="secondary"
									>
										{StaticContent[language].product}
									</Typography>
									<Dropdown
										type="secondary"
										language={language}
										options={products}
										value={product}
										onChange={(val) => {
											setProduct(val);
										}}
									/>
								</FormCell>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
										size="md"
										type="secondary"
									>
										{StaticContent[language].thickness}
									</Typography>
									<Dropdown
										language={language}
										type="secondary"
										options={thicknessOptions}
										value={thickness}
										onChange={(val) => {
											setThickness(val);
										}}
									/>
								</FormCell>
							</FormRow>
							<FormRow>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
											padding-top: 10px;
										`}
										type="secondary"
									>
										{StaticContent[language].standardWidth}
										<LabelIcon>
											<img src="/images/metal-resources/tools-calculators/weight-calculator/w.svg" />
										</LabelIcon>
									</Typography>
									<Flex
										direction="row"
										wrap="nowrap"
										justifyContent="space-between"
									>
										<FlexItem
											css={css`
												width: calc(100% - 94px);
											`}
										>
											<IncrementDecrementInput
												language={language}
												value={standardWidth}
												onChange={(e: any) => {
													setStandardWidth(e.target.value);
												}}
												onIncrement={() => {
													setStandardWidth(Number(standardWidth) + 1);
												}}
												onDecrement={() => {
													if (standardWidth > 0) {
														setStandardWidth(standardWidth - 1);
													}
												}}
											/>
										</FlexItem>
										<FlexItem
											css={css`
												width: 85px;
											`}
										>
											<Dropdown
												language={language}
												type="secondary"
												options={uomOptions[language]}
												value={swUOM}
												onChange={(val) => {
													setSwUOM(val);
												}}
											/>
										</FlexItem>
									</Flex>
								</FormCell>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
											padding-top: 10px;
										`}
										type="secondary"
									>
										{StaticContent[language].finishedWidth}
										<LabelIcon>
											<img src="/images/metal-resources/tools-calculators/weight-calculator/fw.svg" />
										</LabelIcon>
									</Typography>
									<Flex
										direction="row"
										wrap="nowrap"
										justifyContent="space-between"
									>
										<FlexItem
											css={css`
												width: calc(100% - 94px);
											`}
										>
											<IncrementDecrementInput
												language={language}
												value={finishedWidth}
												onChange={(e: any) => {
													setFinishedWidth(e.target.value);
												}}
												onIncrement={() => {
													setFinishedWidth(Number(finishedWidth) + 1);
												}}
												onDecrement={() => {
													if (finishedWidth > 0) {
														setFinishedWidth(finishedWidth - 1);
													}
												}}
											/>
										</FlexItem>
										<FlexItem
											css={css`
												width: 85px;
											`}
										>
											<Dropdown
												language={language}
												type="secondary"
												options={uomOptions[language]}
												value={fwUOM}
												onChange={(val) => {
													setFwUOM(val);
												}}
											/>
										</FlexItem>
									</Flex>
								</FormCell>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
											padding-top: 10px;
										`}
										type="secondary"
									>
										{StaticContent[language].standardLength}
										<LabelIcon>
											<img src="/images/metal-resources/tools-calculators/weight-calculator/l.svg" />
										</LabelIcon>
									</Typography>
									<Flex
										direction="row"
										wrap="nowrap"
										justifyContent="space-between"
									>
										<FlexItem
											css={css`
												width: calc(100% - 94px);
											`}
										>
											<IncrementDecrementInput
												language={language}
												value={standardLength}
												onChange={(e: any) => {
													setStandardLength(e.target.value);
												}}
												onIncrement={() => {
													setStandardLength(Number(standardLength) + 1);
												}}
												onDecrement={() => {
													if (standardLength > 0) {
														setStandardLength(standardLength - 1);
													}
												}}
											/>
										</FlexItem>
										<FlexItem
											css={css`
												width: 85px;
											`}
										>
											<Dropdown
												language={language}
												type="secondary"
												options={uomOptions[language]}
												value={slUOM}
												onChange={(val) => {
													setSlUOM(val);
												}}
											/>
										</FlexItem>
									</Flex>
								</FormCell>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
											padding-top: 10px;
										`}
										type="secondary"
									>
										{StaticContent[language].finishedLength}
										<LabelIcon>
											<img src="/images/metal-resources/tools-calculators/weight-calculator/fl.svg" />
										</LabelIcon>
									</Typography>
									<Flex
										direction="row"
										wrap="nowrap"
										justifyContent="space-between"
									>
										<FlexItem
											css={css`
												width: calc(100% - 94px);
											`}
										>
											<IncrementDecrementInput
												language={language}
												value={finishedLength}
												onChange={(e: any) => {
													setFinishedLength(e.target.value);
												}}
												onIncrement={() => {
													setFinishedLength(Number(finishedLength) + 1);
												}}
												onDecrement={() => {
													if (finishedLength > 0) {
														setFinishedLength(finishedLength - 1);
													}
												}}
											/>
										</FlexItem>
										<FlexItem
											css={css`
												width: 85px;
											`}
										>
											<Dropdown
												language={language}
												type="secondary"
												options={uomOptions[language]}
												value={flUOM}
												onChange={(val) => {
													setFlUOM(val);
												}}
											/>
										</FlexItem>
									</Flex>
								</FormCell>
							</FormRow>
							<FormRow
								css={css`
									margin-bottom: 40px;
								`}
							>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
									>
										{StaticContent[language].numberOfPieces}
									</Typography>
									<IncrementDecrementInput
										language={language}
										value={numberOfPieces}
										onChange={(e: any) => {
											setNumberOfPieces(e.target.value);
										}}
										onIncrement={() => {
											setNumberOfPieces(Number(numberOfPieces) + 1);
										}}
										onDecrement={() => {
											if (numberOfPieces - 1 > 0) {
												setNumberOfPieces(numberOfPieces - 1);
											}
										}}
									/>
								</FormCell>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
									>
										{StaticContent[language].cost}
									</Typography>
									<IncrementDecrementInput
										language={language}
										value={cost}
										onChange={(e: any) => {
											setCost(e.target.value);
										}}
										onIncrement={() => {
											setCost(Number(cost) + 1);
										}}
										onDecrement={() => {
											if (cost > 0) {
												setCost(cost - 1);
											}
										}}
									/>
								</FormCell>
							</FormRow>
							<FormRow
								css={css`
									margin-bottom: 0px;
								`}
							>
								<FormCell>
									<Button
										type="secondary"
										size="sm"
										label={StaticContent[language].calculate}
										fullwidth={true}
										shape="rounded"
										onClick={calculate}
									/>
								</FormCell>
								<FormCell
									css={css`
										max-width: 118px;
										min-width: 118px;
									`}
								>
									<Button
										background={theme.colors.primary.darkGray}
										foreground="light"
										size="sm"
										label={StaticContent[language].reset}
										fullwidth={true}
										shape="rounded"
										onClick={reset}
									/>
								</FormCell>
							</FormRow>
						</FormContainer>
					</CalculatorContainer>
					<ResultsContainer
						css={css`
							background-color: ${theme.colors.primary.darkGray};
						`}
					>
						<ResultsLeft>
							<Typography
								variant="h4"
								css={css`
									font-size: 20px;
									letter-spacing: -1px;
								`}
								color={theme.colors.primary.white}
							>
								{StaticContent[language].potentialSavings + ":"}
							</Typography>
							<Typography variant="div">
								<Typography
									variant="span"
									size="lg"
									color={theme.colors.primary.white}
								>
									{numberOfPieces +
										" " +
										StaticContent[language].pieces +
										" x " +
										formatNumber(pounds) +
										" " +
										StaticContent[language].pounds +
										" x " +
										"$" +
										cost +
										" = "}
									<Typography
										variant="h2"
										color={theme.colors.primary.white}
										css={css`
											display: inline-block;
											margin-left: 5px;
											margin-right: 0px;
											vertical-align: baseline;
											letter-spacing: -2px;
										`}
									>
										{"$" +
											formatNumber(
												Number(savings).toFixed(2).split(".").shift()
											)}
									</Typography>
									{"." + Number(savings).toFixed(2).split(".").pop()}
								</Typography>
							</Typography>
							<Typography
								variant="div"
								color={theme.colors.primary.lightGray}
								size="xs"
							>
								{StaticContent[language].disclaimer}
							</Typography>
						</ResultsLeft>
						<ResultsRight
							css={css`
								display: inline-flex;
								justify-content: flex-end;
							`}
						>
							<Button
								onClick={() => {
									navigate(StaticContent[language].requestAQuoteUrl);
								}}
								label={StaticContent[language].requestAQuote}
								size="xl"
								type="primary"
								paddingType="strict"
							/>
						</ResultsRight>
					</ResultsContainer>
					<Spacer />
				</ContentSection>
			</Media>

			<Media lessThan="lg">
				<ContentSection type="secondary" vPadding="17px">
					<MobileSpacer />
					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
							size="md"
							type="secondary"
						>
							{StaticContent[language].product}
						</Typography>
						<Dropdown
							type="secondary"
							language={language}
							options={products}
							value={product}
							onChange={(val) => {
								setProduct(val);
							}}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
							size="md"
							type="secondary"
						>
							{StaticContent[language].thickness}
						</Typography>
						<Dropdown
							language={language}
							type="secondary"
							options={thicknessOptions}
							value={thickness}
							onChange={(val) => {
								setThickness(val);
							}}
						/>
					</MobileFormCell>
				</ContentSection>
				<ImageContainer
					css={css`
                        background-image: url( /images/metal-resources/tools-calculators/weight-calculator/SheetScrapCalculator.png );
                        width:100%;
                        height: 200px;
                        background-size:auto; calc( 100% - 70px );
                        display:block;
                    `}
				/>
				<ContentSection type="secondary" vPadding="17px">
					<MobileSpacer />

					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
								padding-top: 10px;
							`}
							type="secondary"
						>
							{StaticContent[language].standardWidth}
							<LabelIcon>
								<img src="/images/metal-resources/tools-calculators/weight-calculator/w.svg" />
							</LabelIcon>
						</Typography>
						<Flex direction="row" wrap="nowrap" justifyContent="space-between">
							<FlexItem
								css={css`
									width: calc(100% - 94px);
								`}
							>
								<IncrementDecrementInput
									language={language}
									value={standardWidth}
									onChange={(e: any) => {
										setStandardWidth(e.target.value);
									}}
									onIncrement={() => {
										setStandardWidth(Number(standardWidth) + 1);
									}}
									onDecrement={() => {
										if (standardWidth > 0) {
											setStandardWidth(standardWidth - 1);
										}
									}}
								/>
							</FlexItem>
							<FlexItem
								css={css`
									width: 85px;
								`}
							>
								<Dropdown
									language={language}
									type="secondary"
									options={uomOptions[language]}
									value={swUOM}
									onChange={(val) => {
										setSwUOM(val);
									}}
								/>
							</FlexItem>
						</Flex>
					</MobileFormCell>

					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
								padding-top: 10px;
							`}
							type="secondary"
						>
							{StaticContent[language].finishedWidth}
							<LabelIcon>
								<img src="/images/metal-resources/tools-calculators/weight-calculator/fw.svg" />
							</LabelIcon>
						</Typography>
						<Flex direction="row" wrap="nowrap" justifyContent="space-between">
							<FlexItem
								css={css`
									width: calc(100% - 94px);
								`}
							>
								<IncrementDecrementInput
									language={language}
									value={finishedWidth}
									onChange={(e: any) => {
										setFinishedWidth(e.target.value);
									}}
									onIncrement={() => {
										setFinishedWidth(Number(finishedWidth) + 1);
									}}
									onDecrement={() => {
										if (finishedWidth > 0) {
											setFinishedWidth(finishedWidth - 1);
										}
									}}
								/>
							</FlexItem>
							<FlexItem
								css={css`
									width: 85px;
								`}
							>
								<Dropdown
									language={language}
									type="secondary"
									options={uomOptions[language]}
									value={fwUOM}
									onChange={(val) => {
										setFwUOM(val);
									}}
								/>
							</FlexItem>
						</Flex>
					</MobileFormCell>

					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
								padding-top: 10px;
							`}
							type="secondary"
						>
							{StaticContent[language].standardLength}
							<LabelIcon>
								<img src="/images/metal-resources/tools-calculators/weight-calculator/l.svg" />
							</LabelIcon>
						</Typography>
						<Flex direction="row" wrap="nowrap" justifyContent="space-between">
							<FlexItem
								css={css`
									width: calc(100% - 94px);
								`}
							>
								<IncrementDecrementInput
									language={language}
									value={standardLength}
									onChange={(e: any) => {
										setStandardLength(e.target.value);
									}}
									onIncrement={() => {
										setStandardLength(Number(standardLength) + 1);
									}}
									onDecrement={() => {
										if (standardLength > 0) {
											setStandardLength(standardLength - 1);
										}
									}}
								/>
							</FlexItem>
							<FlexItem
								css={css`
									width: 85px;
								`}
							>
								<Dropdown
									language={language}
									type="secondary"
									options={uomOptions[language]}
									value={slUOM}
									onChange={(val) => {
										setSlUOM(val);
									}}
								/>
							</FlexItem>
						</Flex>
					</MobileFormCell>

					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
								padding-top: 10px;
							`}
							type="secondary"
						>
							{StaticContent[language].finishedLength}
							<LabelIcon>
								<img src="/images/metal-resources/tools-calculators/weight-calculator/fl.svg" />
							</LabelIcon>
						</Typography>
						<Flex direction="row" wrap="nowrap" justifyContent="space-between">
							<FlexItem
								css={css`
									width: calc(100% - 94px);
								`}
							>
								<IncrementDecrementInput
									language={language}
									value={finishedLength}
									onChange={(e: any) => {
										setFinishedLength(e.target.value);
									}}
									onIncrement={() => {
										setFinishedLength(Number(finishedLength) + 1);
									}}
									onDecrement={() => {
										if (finishedLength > 0) {
											setFinishedLength(finishedLength - 1);
										}
									}}
								/>
							</FlexItem>
							<FlexItem
								css={css`
									width: 85px;
								`}
							>
								<Dropdown
									language={language}
									type="secondary"
									options={uomOptions[language]}
									value={flUOM}
									onChange={(val) => {
										setFlUOM(val);
									}}
								/>
							</FlexItem>
						</Flex>
					</MobileFormCell>

					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
						>
							{StaticContent[language].numberOfPieces}
						</Typography>
						<IncrementDecrementInput
							language={language}
							value={numberOfPieces}
							onChange={(e: any) => {
								setNumberOfPieces(e.target.value);
							}}
							onIncrement={() => {
								setNumberOfPieces(Number(numberOfPieces) + 1);
							}}
							onDecrement={() => {
								if (numberOfPieces - 1 > 0) {
									setNumberOfPieces(numberOfPieces - 1);
								}
							}}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
						>
							{StaticContent[language].cost}
						</Typography>
						<IncrementDecrementInput
							language={language}
							value={cost}
							onChange={(e: any) => {
								setCost(e.target.value);
							}}
							onIncrement={() => {
								setCost(Number(cost) + 1);
							}}
							onDecrement={() => {
								if (cost > 0) {
									setCost(cost - 1);
								}
							}}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Button
							type="secondary"
							size="sm"
							label={StaticContent[language].calculate}
							fullwidth={true}
							shape="rounded"
							onClick={calculate}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Button
							background={theme.colors.primary.darkGray}
							foreground="light"
							size="sm"
							label={StaticContent[language].reset}
							fullwidth={true}
							shape="rounded"
							onClick={reset}
						/>
					</MobileFormCell>
					<MobileSpacer />
					<MobileSpacer />
				</ContentSection>
				<ResultsContainer
					css={css`
						background-color: ${theme.colors.primary.darkGray};
						padding-top: 30px;
						padding-left: 20px;
						padding-right: 20px;
						padding-bottom: 30px;
					`}
				>
					<Typography variant="div">
						<Typography variant="div" size="lg" color={theme.colors.primary.white}>
							{numberOfPieces +
								" " +
								StaticContent[language].pieces +
								" x " +
								formatNumber(pounds) +
								" " +
								StaticContent[language].pounds +
								" x " +
								"$" +
								cost +
								" = "}
						</Typography>
						<Typography variant="span" size="lg" color={theme.colors.primary.white}>
							<Typography
								variant="h1"
								color={theme.colors.primary.white}
								css={css`
									display: inline-block;
									vertical-align: baseline;
									letter-spacing: -2px;
								`}
							>
								{"$" + formatNumber(Number(savings).toFixed(2).split(".").shift())}
							</Typography>
							{"." + Number(savings).toFixed(2).split(".").pop()}
						</Typography>
					</Typography>
					<Typography variant="div" color={theme.colors.primary.lightGray} size="xs">
						{StaticContent[language].disclaimer}
					</Typography>
					<Divider
						css={css`
							color: ${theme.colors.primary.white};
						`}
					/>
					<Typography
						variant="div"
						css={css`
							display: block;
						`}
					>
						<Button
							onClick={() => {
								navigate(StaticContent[language].requestAQuoteUrl);
							}}
							label={StaticContent[language].requestAQuote}
							size="sm"
							fullwidth={true}
							type="primary"
						/>
					</Typography>
				</ResultsContainer>
				<ContentSection type="primary" vPadding="25px">
					<Typography variant="div" type="primary" size="lg">
						<Typography variant="span" type="primary" size="lg">
							{heroContent.needHelp}{" "}
						</Typography>
						<Link type="primary" to={heroContent.contactUsUrl}>
							{heroContent.contactUs}
						</Link>
						<Typography variant="span" type="primary" size="lg">
							{" "}
							{heroContent.or}{" "}
						</Typography>
						<Span
							onClick={() => {
								if (window && (window as any).LiveChatWidget) {
									(window as any).LiveChatWidget.call("maximize");
								}
							}}
						>
							<Link type="primary" to={heroContent.liveChatUrl}>
								{heroContent.liveChat}
							</Link>
						</Span>
					</Typography>
					<MobileSpacer />
				</ContentSection>
			</Media>
		</>
	);
};

export default WeightCalculator;
